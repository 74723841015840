import React, { useContext } from "react";
// import { Link } from "react-router-dom";
// import "../Header/Header.css";
import "./BelowHeader.css";
import robotEN from "../../../assets/imgs/RobotBoardHomeEN.png";
import robotFR from "../../../assets/imgs/RobotBoardHomeFR.png";
import { languageContext } from "../../context/LanguageContext";
import translation from "../../../assets/lang/language.json";
const BelowHeader = () => {
    const { language } = useContext(languageContext);
    let robot = null;
    if (language === "fr") {
        robot = robotFR;
    } else {
        robot = robotEN;
    }
    return (
        <section className="text-second">
            <h1>{translation.BelowHeader.titleH1[language]}</h1>
            <div className="below-header-flex">
                <img className="below-header-img" src={robot} alt="robot" />
                <p className="below-header-text">
                    <span>{translation.BelowHeader.spanTest[language]}</span>
                    <span>{translation.BelowHeader.spanTest2[language]}</span>
                    <span className="ex">
                        {translation.BelowHeader.spanTestEx1[language]}
                    </span>
                    <span className="ex">
                        {translation.BelowHeader.spanTestEx2[language]}
                    </span>
                </p>
            </div>
        </section>
    );
    // return (
    //   <section className="text-second">
    //     <div>If a phishing attack reaches their inbox,</div>
    //     <div className="employee-class">3.18% of employees</div>
    //     <div>will go as far as providing credentials!</div>
    //     <Link className="link" to="/contact">
    //       <button type="button" class="btn btn-outline-success btn-lg">
    //         Try Verifa.i.
    //       </button>
    //     </Link>
    //   </section>
    // );
};

export default BelowHeader;
