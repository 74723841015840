import React from "react";
import { Helmet } from "react-helmet-async";
import "./Btc.css";

export default function Btc() {
    return (
        <div>
            <div className="container">
                <Helmet>
                    <title> BTC </title>
                    <meta
                        name="description"
                        content="Calculate how much your company it's in jeopardy when using an email service"
                    />
                    <link rel="canonical" href="/btc" />
                </Helmet>
                <div>
                    <iframe
                        src="https://docs.google.com/forms/d/e/1FAIpQLSenlKHxd4wrboV_y4MqE5RwwfPWwtTit1a_bQBS40VF1EaO9A/viewform?embedded=true"
                        width="640"
                        height="600"
                        className="btc-iframe"
                        title="something"
                    >
                        Loading…
                    </iframe>
                </div>
            </div>
        </div>
    );
}
