import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import "./ContactForm.css";
import translation from "../../../assets/lang/language.json";

const ContactForm = ({ language }) => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                "service_jpmktdv",
                "template_8adlaik",
                form.current,
                "zqMtp_uZtz7QsVqXh"
            )
            .then(
                (result) => {
                    console.log(result.text);
                },
                (error) => {
                    console.log(error.text);
                }
            );
        e.target.reset();
    };

    return (
        <div>
            <form ref={form} onSubmit={sendEmail} className="form">
                <div className="mb-3">
                    <label htmlFor="" className="form-label">
                        {translation.ContactForm.formInput1[language]}
                    </label>
                    <input
                        type="email"
                        className=" mb-4 form-control  bg-transparent border-bottom-0 border-end-0 border-start-0 
                        rounded-0 text-primary font-monospace text-white shadow-none "
                        // id="exampleInputEmail1"
                        name="email"
                        // aria-describedby="emailHelp"
                        required
                    ></input>
                </div>

                <div className="mb-3">
                    <label htmlFor="" className="form-label">
                        {translation.ContactForm.formInput2[language]}
                    </label>

                    <input
                        type="text"
                        className=" mb-4 form-control bg-transparent border-bottom-0 border-end-0 border-start-0 
                        rounded-0 text-primary font-monospace text-white shadow-none"
                        // aria-label="First name"
                        name="firstName"
                        required
                    ></input>
                </div>
                <div className="mb-3">
                    <label htmlFor="" className="form-label">
                        {translation.ContactForm.formInput3[language]}
                    </label>

                    <input
                        type="text"
                        className=" mb-4 form-control bg-transparent border-bottom-0 border-end-0 border-start-0 
                        rounded-0 text-primary font-monospace text-white shadow-none"
                        // aria-label="Last name"
                        name="lastName"
                        required
                    ></input>
                </div>

                <div>
                    <textarea
                        className=" mb-5 form-control"
                        placeholder="message(optional)"
                        // id="floatingTextarea2"
                        name="message"
                    ></textarea>
                </div>
                <button
                    type="submit"
                    value="Send"
                    className="btn  btn-outline-light btn-lg"
                >
                    {translation.ContactForm.formSubmit[language]}
                </button>
            </form>
        </div>
    );
};

export default ContactForm;
