import React from "react";
// import Feature from "./Features/Feature";
import Header from "./Header/Header";
import AiSection from "./AiSection/AiSection";
import BuySection from "./BuySection/BuySection";
import Supporter from "./Supporter/Supporter";
import Footer from "../components/Footer/Footer";
import BelowHeader from "./BelowHeader/BelowHeader";
import { Helmet } from "react-helmet-async";
import translation from "../../assets/lang/language.json";
import { useSearchParams } from "react-router-dom";
import { languageContext } from "../context/LanguageContext";
const HomeComponent = () => {
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();
    const language = searchParams.get("lang") || "en";

    return (
        <div>
            <Helmet>
                <title> {translation["Home"][language]} </title>
                <meta
                    name="description"
                    content="If a phishing attack reaches their inbox,
                    3.18% of employees
                will go as far as providing credentials. Try verifa.i. "
                />
                <link rel="canonical" href="/" />
            </Helmet>
            <languageContext.Provider value={{ language }}>
                <Header />
                <BelowHeader />
                {/* <Feature /> */}
                <AiSection />
                <BuySection />
                <Supporter />
                <Footer />
            </languageContext.Provider>
        </div>
    );
};

export default HomeComponent;
