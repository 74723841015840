import React from "react";
import "./Supporter.css";
import montrealLogo from "../../../assets/imgs/montreal.png";
import bankNational from "../../../assets/imgs/bank-national.png";
import bmo from "../../../assets/imgs/bmo.png";
import cent from "../../../assets/imgs/cent.png";
import google from "../../../assets/imgs/google.png";
import fondation from "../../../assets/imgs/logo-fondation-jab-1.png";
import microsoft from "../../../assets/imgs/microsoft.png";
import mtlInc from "../../../assets/imgs/mtlInc.png";
import startUp from "../../../assets/imgs/startUp.png";
import nextai from "../../../assets/imgs/nextai.png";

const SupporterItem = () => {
  return (
    <div className="supportItem">
      <img src={montrealLogo} alt="" />
      <img src={bankNational} alt="" />
      <img src={bmo} alt="" />
      <img src={cent} alt="" />
      <img src={google} alt="" />
      <img src={fondation} alt="" />
      <img src={mtlInc} alt="" />
      <img src={microsoft} alt="" />
      <img src={nextai} alt="" />
      <img src={startUp} alt="" />
    </div>
  );
};

export default SupporterItem;
