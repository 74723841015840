import React from "react";
import "./Supporter.css";
import SupporterItem from "./SupporterItem";
import Marquee from "react-fast-marquee";
import { useContext } from "react";
import { languageContext } from "../../context/LanguageContext";
import translation from "../../../assets/lang/language.json";

const Supporter = () => {
    const { language } = useContext(languageContext);
    return (
        <div>
            <div className="supporter-title-section">
                <h2 className="text-center">{translation.Supporter[language]}</h2>
            </div>

            <div className="supporter-section">
                <Marquee>
                    <SupporterItem />
                    <SupporterItem />
                    <SupporterItem />
                </Marquee>
            </div>
        </div>
    );
};

export default Supporter;
