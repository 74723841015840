import React from "react";
import { Link, useSearchParams} from "react-router-dom";
import "./Footer.css";
import { BsLinkedin ,BsFacebook} from "react-icons/bs";
import translation from "../../../assets/lang/language.json"
// import { RiFacebook } from "react-icons/ri";
const Footer = () => {
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const language = searchParams.get("lang") || "en";
  return (
    <footer className="footer-section">
      <div className="footer-left-part">
        <Link className="link" to={`/about?lang=${language}`} ><span>{translation.Footer.link1[language]}</span></Link>
        {/* <Link className="link" to="/contact" ><span>Pricing</span></Link> */}
        <Link className="link" to={`/contact?lang=${language}`} ><span>{translation.Footer.link2[language]}</span></Link>
      </div>

      <div className="footer-right-part">
        <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/verifai-security/"> <BsLinkedin /> </a>
        <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/verifaiphishing"> <BsFacebook /> </a>
      </div>
    </footer>
  );
};

export default Footer;
