import React from "react";
import ContactForm from "./ContactForm/ContactForm";
import "./ContactUs.css";
import { Helmet } from "react-helmet-async";
import { useSearchParams } from "react-router-dom";
import translation from "../../assets/lang/language.json"

const ContactUs = () => {
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();
    const language = searchParams.get("lang") || "en";
    return (
        <section className="container">
            <Helmet>
                <title> {translation.Navbar.navLink2[language]} </title>
                <meta
                    name="description"
                    content="  Whether you’re a company, MSP, distributor or IT service provider,
         contact us. "
                />
                <link rel="canonical" href="/contact" />
            </Helmet>
            <div className=" contact-section">
                <div className=" contact-section-left">
                    <div>Let’s get </div> <div>in touch!</div>
                    <div>
                        {translation.ContactUs.textP[language]}
                    </div>
                </div>
                <div className="contact-section-right">
                    <ContactForm language={language} />
                </div>
            </div>
        </section>
    );
};

export default ContactUs;
