import React, { useEffect } from "react";
import { Link, NavLink, useSearchParams } from "react-router-dom";
import NavbarCSS from "./Navbar.module.css";
import verifLogo from "../../../assets/imgs/logo_verif_white.png";
import translation from "../../../assets/lang/language.json";

function Navbar() {
    const [searchParams, setSearchParams] = useSearchParams();
    const language = searchParams.get("lang");
    useEffect(() => {
        if (language) {
            setSearchParams({ lang: language });
        } else {
            setSearchParams({ lang: "en" });
        }
    }, []);

    const navLinkStyles = ({ isActive }) => {
        return {
            fontWeight: isActive ? "bold" : "normal",
            color: isActive ? "#40B3AC" : "white",
        };
    };
    const styles = (lang) => {
        return {
            fontWeight: language === lang ? "bold" : "normal",
            color: language === lang ? "#40B3AC" : "white",
        };
    };
    const onClick = (lang) => setSearchParams({ lang });
    return (
        <header className="container">
            <div className={NavbarCSS.navbar}>
                <Link className={NavbarCSS.link} to={`/?lang=${language}`}>
                    <img
                        className={NavbarCSS["verif-logo"]}
                        src={verifLogo}
                        alt="verif-logo"
                    />
                </Link>
                <div>
                    <ul className={NavbarCSS.navLink}>
                        <li>
                            <NavLink
                                style={navLinkStyles}
                                className={NavbarCSS.link}
                                to={`/?lang=${language}`}
                            >
                                {translation.Navbar.navLink1[language]}
                            </NavLink>
                        </li>

                        <li>
                            <NavLink
                                style={navLinkStyles}
                                className={NavbarCSS.link}
                                to={`/contact?lang=${language}`}
                            >
                                {translation.Navbar.navLink2[language]}
                            </NavLink>
                        </li>

                        <li>
                            <NavLink
                                style={navLinkStyles}
                                className={NavbarCSS.link}
                                to={`/about?lang=${language}`}
                            >
                                {translation.Navbar.navLink3[language]}
                            </NavLink>
                        </li>

                        {/* <li><NavLink  style={navLinkStyles} className={NavbarCSS.link} to="/contact">Pricing</NavLink></li> */}

                        <li>
                            <NavLink
                                style={navLinkStyles}
                                className={NavbarCSS.link}
                                to={`/calculator?lang=${language}`}
                            >
                                {translation.Navbar.navLink4[language]}
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={NavbarCSS.navbar}>
                <div>
                    <ul className={NavbarCSS.navLink}>
                        <li>
                            <span
                                style={styles("en")}
                                className={NavbarCSS.spanLang}
                                onClick={() => onClick("en")}
                            >
                                English
                            </span>
                        </li>
                        <li>
                            <span
                                style={styles("fr")}
                                className={NavbarCSS.spanLang}
                                onClick={() => onClick("fr")}
                            >
                                Français
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
    );
}

export default Navbar;
