import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Navbar from "./Pages/components/Navbar/Navbar";
import HomeComponent from "./Pages/Home/HomeComponent";
import ContactUs from "./Pages/ContactUs/ContactUs";
import AboutUs from "./Pages/AboutUs/AboutUs";
import Calculator from "./Pages/Calculator/Calculator";
import FullCalculator from "./Pages/FullCalculator/FullCalculator";
import Btc from "./Pages/BTC/Btc";


function App() {
    return (
        <BrowserRouter>
            <div className="bg">
                <div className="nav-bg">
                    <Navbar />
                </div>
                <Routes>
                    <Route
                        exact
                        path="/contact"
                        element={<ContactUs />}
                    />
                    <Route exact path="/about" element={<AboutUs />} />
                    <Route
                        exact
                        path="/calculator"
                        element={<Calculator />}
                    />
                    <Route
                        exact
                        path="/full-calculator"
                        element={<FullCalculator />}
                    />
                    <Route
                        exact
                        path="/btc"
                        element={<Btc />}
                    />
                    <Route exact path="/" element={<HomeComponent />} />
                </Routes>
            </div>
        </BrowserRouter>
    );
}

export default App;
