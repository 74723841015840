import React, { useState } from "react";
import "./FullCalculatorForm.css";
import Big from "big.js";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import NumberFormat from "react-number-format";

export default function FullCalculatorForm() {
    const [numberClients, setNumberClients] = useState(new Big(45));
    const [costBEC, setCostBEC] = useState(new Big(21953));
    const [riskBEC, setRiskBEC] = useState(new Big(1.43));
    const [solutionHelp, setSolutionHelp] = useState(new Big("50"));
    let totalCostBEC = numberClients.times(costBEC);
    let riskWithSo = riskBEC.div(100).times(1 - solutionHelp.div(100));
    let riskAdCostWithoutSo = totalCostBEC.times(riskBEC.div(100));
    let riskAdCostWithSo = totalCostBEC.times(riskWithSo);
    let solutionCost = numberClients.times("4.99").times(12);
    let riskAdDifference = riskAdCostWithoutSo.minus(riskAdCostWithSo);
    let ROI = riskAdDifference.minus(solutionCost).div(solutionCost);

    return (
        <div className="mb-3 form">
            <div className="w-90 m-auto">
                <hr className="line-table-calculator" />
                <h4 className="text-center title-calculator">Modify below the data points in this color to reflect your individual case</h4>
                <table className="table table-dark table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Description</th>
                            <th scope="col">Without Verifa.i.</th>
                            <th scope="col">With Verifa.i.</th>
                            <th scope="col">Difference</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <label
                                    htmlFor="number-epl"
                                    className="form-label"
                                >
                                    Number of employees
                                </label>
                            </td>
                            <td colSpan="3">
                                <input
                                    id="number-epl"
                                    type="number"
                                    className="mb-4 form-control  bg-transparent border-bottom-0 border-end-0 border-start-0 
                rounded-0 calculator-input font-monospace shadow-none"
                                    value={numberClients.abs()}
                                    onChange={(e) =>
                                        e.target.value >= 1
                                            ? setNumberClients(
                                                  new Big(e.target.value)
                                              )
                                            : null
                                    }
                                    min="1"
                                    autoFocus
                                    required
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label
                                    htmlFor="cost-bec"
                                    className="form-label"
                                >
                                    Cost of BEC / employee ($)
                                    <Tippy
                                        content={
                                            <span>
                                                591 IT professionals (working
                                                for companies with an average
                                                9,567 headcount) estimated: -
                                                Maximum loss resulting from a
                                                BEC attack's data exfiltration
                                                ($8,120,000) - Maximum loss
                                                resulting from material business
                                                disruptions caused by BEC
                                                ($157,000,000)
                                                (8.12M+157M)/9,567= 17,259 USD
                                                Study available{" "}
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href="https://drive.google.com/file/d/1D2siv3iMLh7eEpe1hAEUNneN_4TqkW7n/view?usp=sharing"
                                                >
                                                    here
                                                </a>
                                            </span>
                                        }
                                        interactive={true}
                                    >
                                        <span className="btn btn-outline-info btn-sm btn-round">
                                            ?
                                        </span>
                                    </Tippy>
                                </label>
                            </td>
                            <td colSpan="3">
                                <NumberFormat
                                    value={costBEC.toFixed(2)}
                                    displayType={"number"}
                                    thousandSeparator={true}
                                    suffix={"$"}
                                    onValueChange={({ value }) =>
                                        setCostBEC(new Big(value))
                                    }
                                    className="mb-4 form-control  bg-transparent border-bottom-0 border-end-0 border-start-0 
                                    rounded-0 calculator-input font-monospace shadow-none"
                                    decimalScale={2}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Total Cost BEC
                                <Tippy
                                    content={
                                        <span>
                                            Number of employees x Cost of BEC /
                                            employee
                                        </span>
                                    }
                                >
                                    <span className="btn btn-outline-info btn-sm btn-round">
                                        ?
                                    </span>
                                </Tippy>
                            </td>
                            <td>
                                <NumberFormat
                                    thousandSeparator={true}
                                    displayType={"text"}
                                    suffix={"$"}
                                    value={totalCostBEC.toFixed(2)}
                                />
                            </td>
                            <td colSpan="2">
                                <NumberFormat
                                    thousandSeparator={true}
                                    displayType={"text"}
                                    suffix={"$"}
                                    value={totalCostBEC.toFixed(2)}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label
                                    htmlFor="risk-bec"
                                    className="form-label"
                                >
                                    Risk of BEC in next 12 months
                                    <Tippy
                                        content={
                                            <span>
                                                591 IT professionals estimated
                                                the likelihood of occurence over
                                                the next 12 months for: -
                                                Maximum loss resulting from a
                                                BEC attack's data exfiltration
                                                ($8,120,000) at 1.09% - Maximum
                                                loss resulting from material
                                                business disruptions caused by
                                                BEC ($157,000,000) at 1.45% For
                                                a weighted average likelyhood of
                                                1.43%. Study available
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href="https://drive.google.com/file/d/1D2siv3iMLh7eEpe1hAEUNneN_4TqkW7n/view?usp=sharing"
                                                >
                                                    here
                                                </a>
                                            </span>
                                        }
                                        interactive={true}
                                    >
                                        <span className="btn btn-outline-info btn-sm btn-round">
                                            ?
                                        </span>
                                    </Tippy>
                                </label>
                            </td>
                            <td>
                                <NumberFormat
                                    value={riskBEC.toNumber()}
                                    displayType={"number"}
                                    suffix={"%"}
                                    onValueChange={({ value }) =>
                                        setRiskBEC(
                                            value > 0
                                                ? new Big(value)
                                                : new Big(0.001)
                                        )
                                    }
                                    className="mb-4 form-control  bg-transparent border-bottom-0 border-end-0 border-start-0 
                                    rounded-0 calculator-input font-monospace shadow-none"
                                />
                            </td>
                            <td>{riskWithSo.times(100).toFixed()}%</td>
                            <td>
                                <NumberFormat
                                    value={solutionHelp.toNumber()}
                                    displayType={"number"}
                                    suffix={"%"}
                                    onValueChange={({ value }) =>
                                        setSolutionHelp(
                                            value > 0
                                                ? new Big(value)
                                                : new Big(0.01)
                                        )
                                    }
                                    className="mb-4 form-control  bg-transparent border-bottom-0 border-end-0 border-start-0 
                                    rounded-0 calculator-input font-monospace shadow-none"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Yearly Risk-Adjusted Cost of BEC
                                <Tippy
                                    content={
                                        <span>
                                            Total cost of BEC x Risk of BEC for
                                            next 12 months
                                        </span>
                                    }
                                >
                                    <span className="btn btn-outline-info btn-sm btn-round">
                                        ?
                                    </span>
                                </Tippy>
                            </td>
                            <td>
                                <NumberFormat
                                    thousandSeparator={true}
                                    displayType={"text"}
                                    suffix={"$"}
                                    value={riskAdCostWithoutSo.toFixed(2)}
                                />
                            </td>
                            <td>
                                <NumberFormat
                                    thousandSeparator={true}
                                    displayType={"text"}
                                    suffix={"$"}
                                    value={riskAdCostWithSo.toFixed(2)}
                                />
                            </td>
                            <td>
                                <NumberFormat
                                    thousandSeparator={true}
                                    displayType={"text"}
                                    suffix={"$"}
                                    value={riskAdDifference.toFixed(2)}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Verifa.i. Solution
                                <Tippy
                                    content={
                                        <span>
                                            Yearly cost of solution based on
                                            number of employees. Cancel anytime.
                                        </span>
                                    }
                                >
                                    <span className="btn btn-outline-info btn-sm btn-round">
                                        ?
                                    </span>
                                </Tippy>
                            </td>
                            <td>-</td>
                            <td>
                                <NumberFormat
                                    thousandSeparator={true}
                                    displayType={"text"}
                                    suffix={"$"}
                                    value={solutionCost.toFixed(2)}
                                />
                            </td>
                            <td>
                                <NumberFormat
                                    thousandSeparator={true}
                                    displayType={"text"}
                                    suffix={"$"}
                                    value={-solutionCost.toFixed(2)}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                ROI
                                <Tippy
                                    content={
                                        <span>
                                            (Difference in yearly risk-adjusted
                                            cost of BEC- Verifa.i.
                                            Solution)/Verifa.i. Solution
                                        </span>
                                    }
                                >
                                    <span className="btn btn-outline-info btn-sm btn-round">
                                        ?
                                    </span>
                                </Tippy>
                            </td>
                            <td colSpan="3">{ROI.times(100).toFixed(2)}%</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}
