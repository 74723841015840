import React from "react";
import { Helmet } from "react-helmet-async";
import CalculatorForm from "./CalculatorForm/CalculatorForm";

export default function Calculator() {
    return (
        <div className="container">
            <Helmet>
                <title> Calculator </title>
                <meta
                    name="description"
                    content="Calculate how much your company it's in jeopardy when using an email service"
                />
                <link rel="canonical" href="/calculator" />
            </Helmet>
            <div>
                <CalculatorForm />
            </div>
        </div>
    );
}
