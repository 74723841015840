import React from "react";
import "./AboutUs.css";
import "react-medium-image-zoom/dist/styles.css";
import mediumZoom from "medium-zoom";
import team1 from "../../assets/imgs/team/team-1.jpg";
import team2 from "../../assets/imgs/team/team-2.jpg";
import team3 from "../../assets/imgs/team/team-3.jpg";
import team4 from "../../assets/imgs/team/team-4.jpg";
import team5 from "../../assets/imgs/team/team-5.jpg";
import { Helmet } from "react-helmet-async";
import { useSearchParams } from "react-router-dom";
import translation from "../../assets/lang/language.json";

function ImageZoom({ zoom, src, alt, background }) {
    const zoomRef = React.useRef(zoom.clone({ background }));

    function attachZoom(image) {
        zoomRef.current.attach(image);
    }

    return <img src={src} alt={alt} ref={attachZoom} />;
}

const AboutUs = () => {
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();
    const language = searchParams.get("lang") || "en";
    const zoom = React.useRef(mediumZoom({ background: "#000", margin: 48 }));
    return (
        <section className="container">
            <Helmet>
                <title> {translation.Navbar.navLink3[language]} </title>
                <meta
                    name="description"
                    content="Verifa.i. is a Montreal-based startup committed to placing the power of technology
          on the side of companies in their fight against looting cybercriminals. "
                />
                <link rel="canonical" href="/about" />
            </Helmet>
            <div className="about-section">
                <div className="contact-section-left">
                    <div></div>
                    <div>About Us</div>
                    <div className="contact-section-left-mainTxt mt-3">
                        <div>
                            {" "}
                            <span className="verif-span">Verifa.i.</span>{" "}
                            {translation.AboutUs.text1[language]}
                            <div className="paragraph-two">
                                {translation.AboutUs.text2[language]}
                            </div>
                        </div>
                    </div>
                    <div className="contact-section-left-mainTxt mt-5">
                        <div>
                            Verif AI’s legal name is Solution iAgree Inc. and was founded by Charles Lesieur in 2019. It’s located at
                            <br />
                            1027 Bd des Entreprises bureau 209
                            <br />
                            Terrebonne, QC J6Y 1V2
                        </div>
                    </div>
                </div>

                <div className="photo-grid">
                    <div className="photo1">
                        <ImageZoom
                            src={team1}
                            zoom={zoom.current}
                            color="#BADA55"
                        />
                    </div>
                    <div className="photo2">
                        <ImageZoom
                            src={team2}
                            zoom={zoom.current}
                            color="#BADA55"
                        />
                    </div>
                    <div className="photo3">
                        <ImageZoom
                            src={team3}
                            zoom={zoom.current}
                            color="#BADA55"
                        />
                    </div>
                    <div className="photo4">
                        <ImageZoom
                            src={team4}
                            zoom={zoom.current}
                            color="#BADA55"
                        />
                    </div>
                    <div className="photo5">
                        <ImageZoom
                            src={team5}
                            zoom={zoom.current}
                            color="#BADA55"
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutUs;
