import React, { useState } from "react";
import "./CalculatorForm.css";
import Big from "big.js";
import "tippy.js/dist/tippy.css";
import NumberFormat from "react-number-format";

export default function CalculatorForm() {
    const [numberClients, setNumberClients] = useState(new Big(45));
    const [costBEC, setCostBEC] = useState(new Big(1453));
    let totalCostBEC = numberClients.times(costBEC);

    return (
        <div className="mb-3 form">
            <div className="w-90 m-auto">
                <hr className="line-table-calculator" />
                <h4 className="text-center title-calculator">
                    <p className="title-calculator-first">
                        Based on a recent{" "}
                        <a
                            href="https://www.ibm.com/security/data-breach"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            report
                        </a>{" "}
                        from IBM, we estimated the average cost of a Data Breach
                        initiated by email attack.
                    </p>
                    <p>
                        This takes into account detection and escalation,
                        notification, post breach response and lost business
                        (reputation, lost customers, downtime,....)
                    </p>
                </h4>
                <table className="table table-dark table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Description</th>
                            <th scope="col">Cost</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <label
                                    htmlFor="number-epl"
                                    className="form-label"
                                >
                                    Number of employees
                                </label>
                            </td>
                            <td colSpan="3">
                                <input
                                    id="number-epl"
                                    type="number"
                                    className="mb-4 form-control  bg-transparent border-bottom-0 border-end-0 border-start-0 
                rounded-0 calculator-input font-monospace shadow-none"
                                    value={numberClients.abs()}
                                    onChange={(e) =>
                                        e.target.value >= 1
                                            ? setNumberClients(
                                                  new Big(e.target.value)
                                              )
                                            : null
                                    }
                                    min="1"
                                    autoFocus
                                    required
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label
                                    htmlFor="cost-bec"
                                    className="form-label"
                                >
                                    Avg Cost of Data Breach from Email Attack
                                    / Employee
                                </label>
                            </td>
                            <td colSpan="3">
                                <NumberFormat
                                    value={costBEC.toFixed(2)}
                                    displayType={"number"}
                                    thousandSeparator={true}
                                    suffix={"$"}
                                    onValueChange={({ value }) =>
                                        setCostBEC(new Big(value))
                                    }
                                    className="mb-4 form-control  bg-transparent border-bottom-0 border-end-0 border-start-0 
                                    rounded-0 calculator-input font-monospace shadow-none"
                                    decimalScale={2}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Total Expected Cost for your company (USD)</td>
                            <td className="total-cost-number">
                                <NumberFormat
                                    thousandSeparator={true}
                                    displayType={"text"}
                                    suffix={"$"}
                                    value={totalCostBEC.toFixed(2)}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p className="title-calculator-footer">What would be the consequences of such a loss to your company?</p>
                <br />
                <br />
                <br />
            </div>
        </div>
    );
}
