import React from "react";
import { Helmet } from "react-helmet-async";
import FullCalculatorForm from "./FullCalculatorForm/FullCalculatorForm";

export default function FullCalculator() {
    return (
        <div className="container">
            <Helmet>
                <title> Calculator </title>
                <meta
                    name="description"
                    content="Calculate how much your company it's in jeopardy when using an email service"
                />
                <link rel="canonical" href="/calculator" />
            </Helmet>
            <div>
                <FullCalculatorForm />
            </div>
        </div>
    );
}
