import React from "react";
import "./AiSection.css";
import image from "../../../assets/imgs/VERIF_icon.png";
// import screenShotOne from "../../assets/imgs/ScreenShot-1.png";
// import screenShotTwo from "../../assets/imgs/ScreenShot-2.png";
import screenshot1EN from "../../../assets/imgs/screenshot1EN.PNG";
import screenshot2EN from "../../../assets/imgs/screenshot2EN.PNG";
import screenshot3EN from "../../../assets/imgs/screenshot3EN.PNG";
import screenshot1FR from "../../../assets/imgs/screenshot1FR.png";
import screenshot2FR from "../../../assets/imgs/screenshot2FR.png";
import screenshot3FR from "../../../assets/imgs/screenshot3FR.png";
// import Pulse from 'react-reveal/Pulse';
// import { useCallback, useState } from "react";
// import { Controlled as ControlledZoom } from "react-medium-image-zoom";
// import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import mediumZoom from "medium-zoom";
import { languageContext } from "../../context/LanguageContext";
import { useContext } from "react";
import translation from "../../../assets/lang/language.json";

function ImageZoom({ zoom, src, alt, background }) {
    const zoomRef = React.useRef(zoom.clone({ background }));

    function attachZoom(image) {
        zoomRef.current.attach(image);
    }

    return <img src={src} alt={alt} ref={attachZoom} />;
}

const AiSection = () => {
    const { language } = useContext(languageContext);
    const zoom = React.useRef(mediumZoom({ background: "#000", margin: 48 }));
    let explain = {
        screenshot1: null,
        screenshot2: null,
        screenshot3: null,
    };
    if (language === "fr") {
        explain.screenshot1 = screenshot1FR;
        explain.screenshot2 = screenshot2FR;
        explain.screenshot3 = screenshot3FR;
    } else {
        explain.screenshot1 = screenshot1EN;
        explain.screenshot2 = screenshot2EN;
        explain.screenshot3 = screenshot3EN;
    }
    return (
        <section>
            <div className="aiSection-title-section">
                <img className="mb-2" src={image} alt="verif-icon " />
                <h1 className="text-center">
                    {translation.AISection.title1[language]}
                </h1>
                <h2 className="text-center">
                    {translation.AISection.title2[language]}
                </h2>
            </div>

            <div className="aiSection-section">
                <ImageZoom
                    src={explain.screenshot1}
                    alt="app sceenshot"
                    zoom={zoom.current}
                    color="#BADA55"
                />
                <ImageZoom
                    src={explain.screenshot2}
                    alt="app sceenshot"
                    zoom={zoom.current}
                    color="#BADA55"
                />
                <ImageZoom
                    src={explain.screenshot3}
                    alt="app sceenshot"
                    zoom={zoom.current}
                    color="#BADA55"
                />

                {/* <Pulse delay={700} right> */}

                {/* <Zoom>
          <img src={screenShotOne} alt="screenShotOne" width="600" />
        </Zoom>

        <Zoom>
          <img src={screenShotTwo} alt="screenShotOne" width="600" />
        </Zoom> */}
                {/* </Pulse> */}
            </div>
        </section>
    );
};

export default AiSection;
