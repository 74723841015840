import React from "react";
import { Link } from "react-router-dom";
import noDemoImg from "../../../assets/imgs/noDemoImg.png";
import "./BuySection.css";
// import Pulse from 'react-reveal/Pulse';
import Fade from "react-reveal/Fade";
import { useContext } from "react";
import { languageContext } from "../../context/LanguageContext";
import translation from "../../../assets/lang/language.json";
const BuySection = () => {
    const { language } = useContext(languageContext);
    return (
        // <Pulse delay={700} right>

        <div className=" BuySection">
            <div className="leftPart text-secondary fw-light ">
                <Fade delay={500} duration={1500} top cascade>
                    <ul className="">
                        <li>Outlook Add-In</li>
                        <li>{translation.BuySection.ulLi1[language]}</li>
                        <li>{translation.BuySection.ulLi2[language]}</li>
                        <li>{translation.BuySection.ulLi3[language]}</li>
                        <li>{translation.BuySection.ulLi4[language]}</li>
                    </ul>
                </Fade>
                <Link className="link" to="/contact">
                    {/* <button type="button" className="btn d-inline btn-outline-success btn-lg">
          Buy
        </button>  */}
                </Link>
            </div>

            <img src={noDemoImg} alt="noDemoImg" />
        </div>

        // </Pulse>
    );
};

export default BuySection;
