import React, { useContext } from "react";
import "./Header.css";
// import HeaderCSS from "./Header.module.css"
import explainEN from "../../../assets/imgs/ExplainEN.png";
import explainFR from "../../../assets/imgs/ExplainFR.png";
import { languageContext } from "../../context/LanguageContext";
import translation from "../../../assets/lang/language.json"
export default function Header() {
    const {language} = useContext(languageContext);
    let explain = null;
    if (language === "fr") {
        explain = explainFR;
    } else {
        explain = explainEN;
    }
    return (
        <div className={"header-container"}>
            <div className="container">
                <h1 className="title-header">{translation.Header[language]} </h1>
                <a
                    href="https://www.lockheedmartin.com/en-us/capabilities/cyber/cyber-kill-chain.html"
                    target={"_blank"}
                    className="link-header"
                >
                    (Lockheed Martin)
                </a>
                <div className="header-img-container">
                    <img
                        className="header-img"
                        src={explain}
                        alt={"img"}
                    />
                </div>
            </div>
        </div>
    );
    // return (
    //   <div className=" container header-animated">
    //     <div className="text-first">
    //       <span className="text-first-span">Cyber</span>
    //       <span className="text-first-span">intrusion</span>
    //       <span className="text-first-span">activity</span>
    //       <span className="text-first-span">jumped</span>
    //       <br />
    //       <span className="text-first-span"><span className="text-warning">125%</span> in 2021</span>
    //       <br />
    //       <span className="text-first-span">
    //         <a
    //           className="text-monospace"
    //           target="_blank"
    //           rel="noopener noreferrer"
    //           href="https://newsroom.accenture.com/news/global-cyber-intrusion-activity-more-than-doubled-in-first-half-of-2021-according-to-accentures-cyber-incident-response-update.htm"
    //         >
    //           - accenture -
    //         </a>
    //       </span>
    //     </div>
    //   </div>
    // );
}
